.dim-image {
    filter: brightness(0.4);
}

.image-with-text {
    position: relative;
    color: white;

    img {
        min-width: 100%;
        min-height: 8rem;
        max-height: 50rem;
        object-fit: cover;
    }

    .banner-text {
        position: absolute;
        top: 50%;

        &-left {
            transform: translate(0, -50%);
            width: 50%;
        }

        &-right {
            right: 0%;
            transform: translate(-100%, -50%);
        }

        &-center {
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
.spacer {
    margin-top: 25px;
    margin-bottom: 25px;
    width:100%; 
    position:relative; 
    
    .mask { 
      overflow:hidden; height:20px; 
      &:after { 
        content:''; 
        display:block; margin:-25px auto 0;
        width:100%; height:25px;  
        border-radius:125px / 12px;  
        box-shadow:0 0 8px black;
      }
    }
  }
  
.spacer-vertical {
    display: inline-block;
    position: relative;
    width: 100%;

    .mask {
        overflow: hidden;
        width: 10px;
        height: 200px;
        margin-left: auto;
        margin-right: auto;
    }
    &.left .mask:after {
        content: '';
        display: block;
        margin-left: -10px;
        width: 20px;
        height: 100%;
        border-radius: 12px / 125px;
        box-shadow: 0 0 8px black;
    }
    &.right .mask:before {
        content: '';
        display: block;
        margin-left: 10px;
        width: 20px;
        height: 100%;
        border-radius: 12px / 125px;
        box-shadow: 0 0 8px black;
    }
}

.two-column {
    color: black;
}